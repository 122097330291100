import React from "react"
import { graphql } from "gatsby"
import Features from "~components/common/features"
import Feature from "~components/common/feature"
import Layout from "~components/layouts/default"
import SEO from "~components/common/seo"
import Container from "~components/common/container"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Image from "gatsby-image"

const Page = ({ data }) => (
  <Layout>
    <SEO
      title="Karriere"
      description="Wir sind immer auf der Suche nach qualifizierten Mitarbeitern, die unser Team bereichern und freuen uns über deine Initiativbewerbung per Mail an info@kammtec.de."
    />
    <Container>
      <div className="xl:w-2/3 xl:mx-auto">
        <div className="gutter my-screen-lg lg:w-5/8">
          <h1>KammTec – das "K" steht für Karriere.</h1>
          <p>
            Wir sind immer auf der Suche nach qualifizierten Mitarbeitern, die
            unser Team bereichern und freuen uns über deine Initiativbewerbung
            per Mail an
            <a
              href={`mailto:${data.info.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-brown-light"
            >
              {" "}
              {data.info.email}
            </a>
          </p>
        </div>
      </div>
    </Container>

    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter lg:w-6/12 xl:w-1/2">
          <p>Wir stellen ein</p>
          <h1>
            Tischler (m/w/d) <br /> Vollzeit ab sofort!
          </h1>
        </div>
      </div>
    </Container>
    <Container>
      <div className="gutter">
        <h3>Das ist dein Aufgabenbereich:</h3>
        <Features>
          <Feature>
            Abwechslungsreiche handwerkliche Tätigkeit im Möbel-, Laden- und
            Innenausbau
          </Feature>
          <Feature>
            Eine große Bandbreite an unterschiedlichen Projekten in der
            Produktion oder bei Montagetätigkeiten
          </Feature>
          <Feature>Arbeit mit einem modernen Maschinenpark</Feature>
        </Features>
        <h3>Das bringst du mit:</h3>
        <Features>
          <Feature>
            Erfolgreich abgeschlossene Ausbildung zum Tischlergesellen oder zur
            Tischlergesellin
          </Feature>
          <Feature>Fundierte Kenntnisse im Bereich Laden- & Möbelbau</Feature>
          <Feature>Führerschein Klasse B (BE wünschenswert)</Feature>
          <Feature>
            Teamfähigkeit, Zuverlässig- und Pünktlichkeit sowie
            Verantwortungsbewusstsein
          </Feature>
          <Feature>
            Hohe Einsatzbereitschaft & eine selbstständige Arbeitsweise
          </Feature>
        </Features>
        <h3>Das erwartet dich:</h3>
        <Features>
          <Feature>
            Ein sicherer Arbeitsplatz in einem erfolgreichen & modernen
            Unternehmen
          </Feature>
          <Feature>
            Eine spannende, abwechslungsreiche & unbefristete Tätigkeit
          </Feature>
          <Feature>
            Traditionelle Handwerkskunst in einer High-End Produktionsstätte auf
            3.000 m²
          </Feature>
          <Feature>Übertariflicher Urlaubsanspruch</Feature>
          <Feature>Ein tolles Arbeitsklima & nette Kollegen</Feature>
          <Feature>Kurze Entscheidungswege</Feature>
        </Features>
        <h3 className="italic">Dir gefällt was du liest?</h3>
        <p className="max-w-3xl pb-24">
          Schick uns deine Bewerbungsunterlagen (Lebenslauf, Anschreiben,
          letztes Zeugnis) gerne als PDF an{" "}
          <a
            href={`mailto:${data.info.email}`}
            className="text-white hover:text-brown-light whitespace-nowrap"
          >
            <FontAwesomeIcon
              icon={["fal", "paper-plane"]}
              fixedWidth
              className="mr-xs"
            />
            {data.info.email}{" "}
          </a>
          . Wir freuen uns auf Dich!
        </p>
      </div>
    </Container>

    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter lg:w-6/12 xl:w-1/2">
          <p>Wir bieten an</p>
          <h1>Ausbildung zum/zur Tischlergesellen/in für 2025</h1>
        </div>
        <div className="gutter md:w-6/12 lg:order-last xl:w-1/3 xl:mt-2xl">
          {data.imageTopArbeitgeber && (
            <Image
              fluid={data.imageTopArbeitgeber.childImageSharp.fluid}
              alt="Top Arbeitgeber"
              title="Top Arbeitgeber"
            />
          )}
        </div>
      </div>
    </Container>
    <Container>
      <div className="gutter">
        <div className="max-w-3xl">
          <p>
            Du willst mit deinen eigenen Händen besondere Dinge erschaffen?{" "}
            <br />
            Du willst am Ende des Tages mit Stolz auf deine Arbeit zurückblicken
            können?
            <br />
            Du willst deine Leidenschaft zu deinem Beruf machen?
          </p>
          <p>
            Dann starte jetzt deine Ausbildung zum Tischler / zur Tischlerin bei
            KammTec.
          </p>
        </div>
        <h3>Das erwartet dich:</h3>
        <Features>
          <Feature>
            Eine Top-Ausbildung in einem jungen und zielstrebigen Unternehmen
          </Feature>
          <Feature>
            Traditionelle Handwerkskunst in einer High-End Produktionsstätte auf
            3.000 m²
          </Feature>
          <Feature>
            Tatkräftige Unterstützung bei deiner Ausbildung durch unser gut
            geschultes Ausbilder-Team
          </Feature>
          <Feature>Ein tolles Arbeitsklima und nette Kollegen</Feature>
          <Feature>Überdurchschnittliche Vergütung</Feature>
        </Features>
        <h3>Das bringst du mit:</h3>
        <Features>
          <Feature>
            Eine abgeschlossene Schulausbildung (guter Hauptschulabschluss /
            Fachoberschulreife)
          </Feature>
          <Feature>
            Handwerkliches Geschick & Freude am Umgang mit dem Werkstoff Holz
          </Feature>
          <Feature>Gute Noten im Bereich Mathematik & Technik</Feature>
          <Feature>Gutes, räumliches Vorstellungsvermögen</Feature>
          <Feature>
            Teamfähigkeit, da wir Freundlichkeit als Grundsatz ansehen
          </Feature>
        </Features>
        <h3 className="italic">Dir gefällt was du liest?</h3>
        <p className="max-w-3xl">
          Schick uns deine Bewerbungsunterlagen (Lebenslauf, Anschreiben,
          letztes Zeugnis) gerne als PDF an{" "}
          <a
            href={`mailto:${data.info.email}`}
            className="text-white hover:text-brown-light whitespace-nowrap"
          >
            <FontAwesomeIcon
              icon={["fal", "paper-plane"]}
              fixedWidth
              className="mr-xs"
            />
            {data.info.email}{" "}
          </a>
          {/* oder kontaktieren uns per{" "}
        <a
          href={`https://wa.me/${data.info.mobile.machine}`}
          className="text-white hover:text-brown-light whitespace-nowrap"
        >
          <FontAwesomeIcon
            icon={["fab", "whatsapp"]}
            fixedWidth
            className="mr-xs fa-lg"
          />
          {data.info.mobile.human}
        </a>*/}
        </p>
      </div>
    </Container>
    <Container>
      <div className="my-screen-lg lg:flex lg:items-center">
        <div className="gutter w-1/3">
          {data.imageTischlerNrw && (
            <Image
              className="xl:w-2/3"
              fluid={data.imageTischlerNrw.childImageSharp.fluid}
              alt="Tischler NRW"
              title="Tischler NRW"
            />
          )}
        </div>
        <div className="gutter lg:order-last xl:w-2/3">
          <h1>Worauf wartest du noch?</h1>
          <p>
            <a
              className="text-white hover:text-brown-light"
              href="https://www.handwerk.de/berufsprofile/tischler-in-schreiner-in.html"
            >
              #triffdennagelaufdenkopf
            </a>
          </p>
        </div>
      </div>
    </Container>
  </Layout>
)

export const query = graphql`
  query {
    imageTopArbeitgeber: file(relativePath: { eq: "toparbeitgeber.png" }) {
      childImageSharp {
        fluid(maxWidth: 834, maxHeight: 312, quality: 72) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTischlerNrw: file(relativePath: { eq: "tischlernrw.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, maxHeight: 620, quality: 72) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    info: dataJson {
      email
      mobile {
        human
        machine
      }
    }
  }
`

export default Page
